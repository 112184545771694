import * as React from "react";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";
import Header, { CurrentPageType } from "../components/Header";
import Footer from "../components/Footer";
import ReactGA from 'react-ga';

// markup
const NotFoundPage = () => {
  React.useEffect(() => {
    ReactGA.initialize('UA-88504612-1');
    ReactGA.pageview("/404");
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>Pixel Works Software: Page Not Found</title>
        <meta name="description" content="The page you are looking for does not exist. Return to the Pixel Works Software homepage." />
        <meta name="robots" content="noindex, follow" />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.pixelworkssoftware.com/404" />
        <meta property="og:title" content="Pixel Works Software: Page Not Found" />
        <meta property="og:description" content="The page you are looking for does not exist. Return to the Pixel Works Software homepage." />
        
        {/* Twitter */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:url" content="https://www.pixelworkssoftware.com/404" />
        <meta name="twitter:title" content="Pixel Works Software: Page Not Found" />
        <meta name="twitter:description" content="The page you are looking for does not exist. Return to the Pixel Works Software homepage." />
      </Helmet>
      <div className="gradient"></div>
      <Header currentPage={CurrentPageType.OTHER} />
      <main style={{maxWidth: "1024px", margin: "auto", padding: "40px 20px", textAlign: "center"}}>
        <h1>Page Not Found</h1>
        <p style={{marginBottom: "30px"}}>
          Sorry, the page you are looking for does not exist or has been moved.
        </p>
        <p>
          <Link to="/apps" style={{color: "#EB0000", textDecoration: "underline"}}>Return to homepage</Link>
        </p>
      </main>
      <Footer />
    </React.Fragment>
  )
}

export default NotFoundPage
